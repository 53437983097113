import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

export default class ApplicationError extends React.Component {

  render() {
    return <Content>
      <div className="login-form" onSubmit={this.handleSubmit}>
        <div className="logo" style={{padding:"0px 50px 15px 50px"}}>
          <img alt="logo" src="/logo.png" style={{width: '100%', borderRadius: 400}} />
        </div>
        <h1>Welcome to Draw API</h1>
        <h2 className="error">{this.props.message}</h2>
      </div>
    </Content>
  }
}