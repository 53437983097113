import Api from '../api';

class Sites {
  selectyedKey = "selected_site"
  selected = null;
  sites = [];
  events = {};

  emit(event, value) {
    if(!this.events[event]) {
      return;
    }

    this.events[event].forEach(handler => {
      handler(value);
    })
  }

  on(event, handler) {
    if(!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(handler)
  }

  getDefaultSite() {
    return new Promise((resolve, reject) => {
      let selectedSite = window.localStorage.getItem(this.selectyedKey);
      this
        .all()
        .then(sites => {
          this.sites = sites;
          if(selectedSite) {
            let site = sites.filter(site => site.id === selectedSite).pop()
            if(site) {
              this.setSelected(site)
            } else {
              this.setSelected(sites[0])
            }
          } else {
            this.setSelected(sites[0])
          }
          resolve();
        })
        .catch(reject)
    });
    
  }

  setSelected(site) {
    if(!site) return;
    this.selected = site;
    window.localStorage.setItem(this.selectyedKey, site.id)
    this.emit("updateSelected", this.selected)
  }

  getSelected() {
    return this.selected || {};
  }

  getOne(id) {
    return new Promise((resolve, reject) => {
      if(this.sites) {
        let localSite = this.sites.filter(site => site.id === id).pop();
        if(localSite) {
          return resolve(localSite);
        }
      }
    });
  }

  all(limit, offset) {
    return Api.get(`/sites`)
  }

  create(site) {
    return Api.post(`/sites`, site)
  }
}


export default new Sites()