import React from 'react';
import TemplatesList from '../../../components/mock/templates/list'
import { PageHeader, Descriptions, Layout } from 'antd';

const { Content } = Layout;


export default class TemplatesPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      initLoading: true,
      loading: false,
      data: [],
      list: [],
    };

  }

  render() {
    return <>
      <PageHeader ghost={false} title="Mocks → Templates">
        <Descriptions size="small" column={3}>
          <Descriptions.Item>Templates management</Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        <div style={{ padding: 24, background: '#fff', textAlign: 'left' }}>
          <TemplatesList></TemplatesList>
        </div>
      </Content>
    </>
  }
}