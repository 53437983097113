import React from 'react';
import { Select, Input } from 'antd';

const { Option } = Select;
const InputGroup = Input.Group;

export default class PathConfigurator extends React.Component {

  render() {
    return <InputGroup style={{display: "flex"}} compact>
      <Select
        defaultValue="GET"
        onChange={(method) => { this.props.onChangeMethod(method) }}
        value={this.props.method}
        style={{ width: 90 }}>
        <Option value="GET">GET</Option>
        <Option value="POST">POST</Option>
        <Option value="PUT">PUT</Option>
        <Option value="DELETE">DELETE</Option>
      </Select>
      <span style={{
            display: !!this.props.category ? "inherit" : "none",
            padding: "6px 10px 0px 10px",
            lineHeight: 1.5,
            background: "#f1f1f1",
            borderTop: "1px solid #d9d9d9",
            borderBottom: "1px solid #d9d9d9"
      }}>{this.props.category}</span>
      <Input
        style={{
          borderLeft: "none",
          paddingLeft: "0px"
        }}
        onChange={(e) => { this.props.onChangePath(e.target.value) }}
        defaultValue="route"
        allowClear
        value={this.props.path}
      />
    </InputGroup>
  }
}