import React from 'react';
import { PageHeader, Layout, Form, Input, Button, message } from 'antd';

import User from '../../../services/user'
import Sites from '../../../services/sites'

const { Content } = Layout;


class AccountDetailPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      initLoading: true,
      loading: false,
      user: null,
      sites: null,
      password: null,
    };

    this.loadUserDetails();
    this.loadUserSites();
  }

  loadUserDetails = () => {
    User
      .details()
      .then(user => {
        this.setState({user})
      })
  }

  loadUserSites = () => {
    Sites
      .all()
      .then(sites => {
        this.setState({sites})
      })
  }

  handleConfirmBlur = e => {
    if(this.state.password === e.target.value) {
      this.updateDetailsValue("password", e.target.value)
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({saving: true})

    User
      .update(this.state.user)
      .then(resp => {
        this.setState({saving: false, dirty: false})
        message.success("User has been saved");
      })
  };

  updateDetailsValue = (key, value) => {
    let user = this.state.user;
    user[key] = value;
    this.setState({user})
  }

  updatePasswordValue = (password) => {
    this.setState({password})
  }

  renderDetailsForm = () => {

    if(!this.state.user) {
      return
    }

    return (<Form style={{width:400, margin: "0 auto"}} onSubmit={this.handleSubmit}>

      <Form.Item label="Firstname">
        <Input placeholder="Your Firstname" onChange={(e) => { this.updateDetailsValue("firstname", e.target.value) }} value={this.state.user.firstname} />
      </Form.Item>

      <Form.Item label="Lastname">
        <Input placeholder="Your Lastname" onChange={(e) => { this.updateDetailsValue("lastname", e.target.value) }} value={this.state.user.lastname} />
      </Form.Item>

      <Form.Item label="E-mail">
        <Input placeholder="Your E-mail" onChange={(e) => { this.updateDetailsValue("email", e.target.value) }} value={this.state.user.email} />
      </Form.Item>

      <Form.Item label="Password" onChange={(e) => { this.updatePasswordValue(e.target.value) }} hasFeedback>
        <Input.Password autoComplete="new-password"   />
      </Form.Item>

      <Form.Item label="Confirm Password" hasFeedback>
        <Input.Password disabled={!this.state.password} onBlur={this.handleConfirmBlur} />
      </Form.Item>

      <Form.Item label="Ornagisation">
        <Input placeholder="Your Organization"  onChange={(e) => { this.updateDetailsValue("company", e.target.value) }} value={this.state.user.company} />
      </Form.Item>

      <Button type="primary" htmlType="submit" style={{width: "100%"}} className="login-form-button">
        Save
      </Button>

    </Form>)
  }

  render() {
    return <>
      <PageHeader ghost={false} title="Account Details"></PageHeader>
      <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        
        
        <div style={{ padding: 24, background: '#fff', textAlign: 'left' }}>
          {this.renderDetailsForm()}
        </div>
      </Content>
    </>
  }
}


export default Form.create()(AccountDetailPage)