import React from 'react';
import { Col, Card, Form, Input, Select  } from 'antd';

import Latency from  '../../../commons/controls/latency'
import Headers from  '../../../commons/controls/headers'
import StatusCode from  '../../../commons/controls/statusCode'

const { Option } = Select;
const { TextArea } = Input;

export default class RouteHandler extends React.Component {

  handleChange = (code) => {
    this.props.handler.plainConfig.code = code;
  }

  propageUpdate = (handler) => {
    this.props.onChange(this.props.index, handler)
  }

  pushHeaders = (headers) => {
    Object.keys(headers).forEach(key => {
      let updated = false;
      for(let index = 0; index < this.props.handler.plainConfig.headers.length; index++) {
        if (key === this.props.handler.plainConfig.headers[index][0]) {
          this.props.handler.plainConfig.headers[index][1] = headers[key];
          updated = true;
        }
      }
      if(!updated) {
        this.props.handler.plainConfig.headers.push([key, headers[key]]);
      }
    })
    this.propageUpdate(this.props.handler)
  }

  generateTemplatesList(template) {
    return <Col span={12}>
        <Select
          onChange={(value) => {
            this.updateBody(0, "template", value)
            const template = this.props.templates.filter(item => item.id === value).pop();
            this.pushHeaders(template.meta)
          }}
          defaultValue="GET"
          value={template}
          style={{ width: 260 }}>
            {this.props.templates.map(item => {
              return <Option key={item.id} value={item.id}>{item.name}</Option>
            })}
        </Select>
      </Col>
  }

  generateBody = (bodies) => {
    if(!bodies) return;
    const body = bodies[0]

    switch(body.type) {
      case 'template':
        return this.generateTemplatesList(body.template);
      case 'plain':
        return <TextArea
          rows={6}
          onChange={(e) => {
            this.updateBody(0, "content", e.target.value)
          }}
          type="textarea"
          value={body.content} />
      default:
        return "" 
    }
  }

  updateConfig = (key, value) => {
    this.props.handler.plainConfig[key] = value;
    this.propageUpdate(this.props.handler)
  }

  updateHandlerValue = (key, value) => {
    this.props.handler[key] = value;
    this.propageUpdate(this.props.handler);
  }

  updateBody = (index, key, value) => {
    this.props.handler.plainConfig.body[index][key] = value;
    this.propageUpdate(this.props.handler)
  }

  render() {
    const {handler} = this.props;

    return <Card style={{marginBottom: "20px"}}>
      <Form layout="horizontal">

        <Col span={12}>
          <Form.Item label="Status Code">
            <StatusCode value={handler.plainConfig.code} onChange={(value) => this.updateConfig("code", value)} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Latency">
            <Latency latency={handler.latency} onChange={(value) => { this.updateHandlerValue("latency", value) }} />
          </Form.Item>
        </Col>
        
        <Col span={24}>
          <Form.Item label="Headers">
            <Headers headers={handler.plainConfig.headers} onChange={(value) => { this.updateConfig("headers", value) }} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="Body">
            <Col span={12}>
              <Select
                onChange={(value) => {
                  this.updateBody(0, "type", value)
                }}
                defaultValue="plain"
                value={handler.plainConfig.body[0].type}
                style={{ width: 260 }}>
                <Option value="plain">Plain</Option>
                <Option value="template">Template</Option>
                <Option value="empty">Empty</Option>
              </Select>
            </Col>
            {this.generateBody(handler.plainConfig.body)}
          </Form.Item>
        </Col>

      </Form>
    </Card>
  }
}