import React from 'react';

import Routes from '../../../../services/routes'
import Sites from '../../../../services/sites'
import Templates from '../../../../services/templates'
import Categories from '../../../../services/categories'

import { List, Typography, Drawer, Skeleton, Select, Input, Button, Pagination, Icon, Col, Row, Tooltip, message } from 'antd';
import RouteHandler from '../handler'
import Confirm from '../../../commons/confirm'

import MethodTag from '../../../commons/methodTag'
import PathConfigurator from '../../../commons/controls/pathConfigurator';
import Path from '../../../commons/path';
import LogsPannel from '../logsPannel';

const { Option } = Select;
const InputGroup = Input.Group;
const { Text } = Typography;

export default class RoutesList extends React.Component {
  state = {
    initLoading: false,
    loading: false,
    saving: false,
    details: null,
    detailsIndex: null,
    dirty: false,
    data: [],
    list: [],
    logs: [],
    routes: [],
    templates: [],
    categories: [],
    pagination: {
      limit: 10,
      page: 1
    },
    filters: {}
  }

  constructor(props) {
    super(props)

    this.loadElements();

    Sites.on('updateSelected', () => {
      this.loadElements();
    })
  }

  updateFilter = (key, value) => {
    let filters = this.state.filters;

    filters[key] = value;

    this.setState({filters})
  }

  generateFilters = () => {
    return <Row>
      <InputGroup compact>
      <Col span={2}>
        <Select defaultValue="GET" onChange={(e) => { this.updateFilter("method", e) }} value={this.state.filters.method} style={{ width: "100%" }}>
          <Option value="">All</Option>
          <Option value="GET">GET</Option>
          <Option value="POST">POST</Option>
          <Option value="PUT">PUT</Option>
          <Option value="DELETE">DELETE</Option>
        </Select>
      </Col>
      <Col span={4}>
        <Input
          onChange={(e) => {
            this.updateFilter("name", e.target.value)
          }}
          defaultValue="Route name"
          placeholder="Search by name"
          value={this.state.filters.name}
        />
      </Col>
      <Col span={12}>
        <Input
          onChange={(e) => {
            this.updateFilter("path", e.target.value)
          }}
          defaultValue="Path name"
          placeholder="Search by path"
          value={this.state.filters.path}
        />
      </Col>
      <Col span={4}>
        <Select onChange={(e) => { this.updateFilter("category_id", e) }}  value={this.state.filters.category_id} style={{ width: "100%", marginBottom: 16 }}>
          <Option key="all" value="">All Categories</Option>
          {this.state.categories.map(category => (
            <Option key={category.id} value={category.id}>{category.name}</Option>
          ))}
        </Select>
      </Col>
      </InputGroup>
    </Row>
  }

  loadElements = () => {
    this.loadRoutes();
    this.loadTemplates();
    this.loadCategories();
  }

  loadRoutes() {
    Routes
      .all(Sites.getSelected().id)
      .then(data => {
        this.setState({routes: data})
      })
  }

  loadTemplates() {
    Templates
      .all(Sites.getSelected().id)
      .then(templates => {
        this.setState({templates})
      })
  }

  loadCategories = () => {
    Categories
      .all(Sites.getSelected().id)
      .then(categories => {
        this.setState({categories})
      })
      .catch(error => {
        message.error('Unable to load Categories. Please try again later');
        console.log(error);
      })
  }

  deleteRoute(id, index, callback) {
    Routes
      .delete(Sites.getSelected().id, id)
      .then(() => {
        let routes = this.state.routes;
        routes.splice(index, 1);
        this.setState({routes: routes})

        if("function" == typeof callback) {
          callback();
        }
      })
      .catch((error) => {
        message.error('Unable to delete Route');
        console.log(error)
      })
  }

  pushNewEmptyRoute = () => {
    let emptyRoute = {
      method: "GET",
      path: "/",
      site: Sites.getSelected().id,
      handler: {
        entries: [{
          "type":"plain","weight":1,
          "latency":{
            "unit":"ms",
            "from":0,
            "to":0
          },
          "plainConfig":{
            "headers":[],
            "code":200,
            "body":[{
              "type":"plain",
              "content":""
            }],
            "Vars":{}
          }
        }]
      }
    };

    this.setState({details: emptyRoute})
  }

  applyFilter = (route) => {
    
    if(!!this.state.filters.category_id && route.category_id !== this.state.filters.category_id) {
      return
    }

    if(!!this.state.filters.method && route.method !== this.state.filters.method) {
      return
    }

    if(!!this.state.filters.name && route.name.toLowerCase().indexOf(this.state.filters.name.toLowerCase()) === -1) {
      return
    }

    if(!!this.state.filters.path && route.path.toLowerCase().indexOf(this.state.filters.path.toLowerCase()) === -1) {
      return
    }
    
    return route
  }

  getRoutesToBeShown = () => {
    const from = this.state.pagination.limit*(this.state.pagination.page-1);
    const output = this.state.routes.filter(this.applyFilter).slice(from, from+this.state.pagination.limit);

    return output;
  } 

  duplicate = (item) => {
    let newItem = JSON.parse(JSON.stringify(item));
    newItem.id = null;
    newItem.name += " - copy";
    newItem.path += "-copy";

    this.setState({details: newItem, dirty: true})
  }

  displayLogs = (route) => {
    
    this.setState({logsDetails: route.id})
  }

  generateList = () => {
    const { initLoading } = this.state;
    const routes = this.getRoutesToBeShown();
    
    return <>
      {this.generateFilters()}
      <List
      className="demo-loadmore-list"
      loading={initLoading} 
      itemLayout="horizontal"
      dataSource={routes}
      renderItem={(item, index) => (
        <List.Item
          key={`routes-list-line-${index}`}>
          <Skeleton title={false} loading={item.loading} active>
            <List.Item.Meta
              title={
                <>
                  <Col span={2}><MethodTag method={item.method} /></Col>
                  <Col span={4}>{item.name || <Text type="secondary">Unnamed Route</Text>}</Col>
                  <Col span={12}> <Path category={item.category_path} path={item.path} /></Col>
                  <Col span={4}>{item.category_name}</Col>
                  <Col span={2} className="list-actions">
                    <Tooltip title="Edit Route">
                      <Button key="list-loadmore-edit" type="link" onClick={() => { this.setState({dirty: false, detailsIndex: index, details: JSON.parse(JSON.stringify(item))}) }}><Icon type="edit" /></Button>
                    </Tooltip>
                    <Tooltip title="Get Logs">
                      <Button type="link" onClick={() => { this.displayLogs(item) }}><Icon type="code" /></Button>
                    </Tooltip>
                    <Tooltip title="Duplicate Route">
                      <Button type="link" onClick={() => { this.duplicate(item) }}><Icon type="copy" /></Button>
                    </Tooltip>
                    <Tooltip title="Delete Route">
                      <Confirm key={`list-loadmore-delete-${index}`} onClick={(cb) => { return this.deleteRoute(item.id, index, cb) }}><Icon type="delete" /></Confirm>
                    </Tooltip>
                  </Col>
                </>
              }
            />
          </Skeleton>
        </List.Item>
      )}
    />
    <div style={{width:"100%", padding:"5px 0", textAlign:"center"}}>
      <Button type="primary" icon="plus" size="small" onClick={this.pushNewEmptyRoute}>Create new Mock</Button>
    </div>
    <Pagination
      style={{marginTop: "20px"}}
      showSizeChanger
      pageSizeOptions={['5', '10', '20', '30', '40']}
      onChange={this.onUpdatePagination}
      onShowSizeChange={this.onUpdatePaginationSize}
      size="small"
      pageSize={this.state.pagination.limit}
      current={this.state.pagination.page}
      total={this.state.routes.length}
      />
    </>
  }

  onUpdatePagination = (page) => {
    let pagination = this.state.pagination;
    pagination.page = page;

    this.setState({ pagination })
  }

  onUpdatePaginationSize = (current, limit) => {
    let pagination = this.state.pagination;
    pagination.limit = limit;

    this.setState({ pagination })
  }

  onClose = () => {
    this.setState({details: null})
  }

  updateDetailsValue = (key, value) => {
    let state = this.state;
    state.details[key] = value;
    state.dirty = true;
    this.setState(state);
  }

  updateDetailsValues = (entries) => {
    let state = this.state;

    Object.keys(entries).forEach(key => {
      state.details[key] = entries[key];
    })

    state.dirty = true;
    this.setState(state);
  }

  updateHandler = (index, handler) => {
    let state = this.state;
    state.details.handler.entries[index] = handler;
    state.dirty = true;
    this.setState(state);
  }

  deleteHandler = (index) => {
    let state = this.state;
    state.details.handler.entries.splice(index, 1)
    state.dirty = true;
    this.setState(state);
  }

  saveDetails = () => {
    this.setState({saving: true})

    Routes
      .update(Sites.getSelected().id, this.state.details)
      .then(resp => {
        let routes = this.state.routes;
        routes[this.state.detailsIndex] = JSON.parse(JSON.stringify(this.state.details));

        this.setState({saving: false, dirty: false})
        message.success("Route has been saved");
      })
      .catch(error => {
        message.error("Unable to save route");
        console.log(error);
      })
  }

  saveNewRoute = () => {
    this.setState({saving: true})

    Routes
      .create(Sites.getSelected().id, this.state.details)
      .then(resp => {
        this.setState({saving: false, dirty: false})
        message.success("Route has been saved");
        this.state.routes.unshift(resp)

        this.setState({routes: this.state.routes, details: null})
      })
  }

  updateCategoryValue = (id) => {
    if(id === "") {
      this.updateDetailsValues({
        "category_name": null,
        "category_path": null,
        "category_id": null
      });
    } else {
      let category = this.state.categories.filter(item => item.id === id).pop();
  
      this.updateDetailsValues({
        "category_name": category.name,
        "category_path": category.base_path,
        "category_id": category.id
      });
    }


  }

  generateDetails = () => {
    if(!this.state.details) return;

    const {details} = this.state;

    return <Drawer
      title="Edit mock"
      width={650}
      onClose={this.onClose}
      visible={!!this.state.details}
      bodyStyle={{ paddingBottom: 100, background: "#f0f2f5" }}
    >
      {/* Suppression / Distribution / Variables */}
      
      
      <div style={{ marginBottom: 16 }}>
        <label className="details">Route name</label>
        <Input
          onChange={(e) => {
            this.updateDetailsValue("name", e.target.value)
          }}
          defaultValue="Route name"
          placeholder="Route name"
          value={details.name}
        />
      </div>

      <label className="details">Route category</label>
      <Select onChange={(e) => { this.updateCategoryValue(e) }} value={details.category_id} style={{ width: "100%", marginBottom: 16 }}>
        <Option key="null" value="">No Category</Option>
        {this.state.categories.map(category => (
          <Option key={category.id} value={category.id}>{category.name}</Option>
        ))}
      </Select>

      <div style={{ marginBottom: 16 }}>
      <label className="details">Route method and path</label>
        <PathConfigurator
          method={details.method}
          category={details.category_path}
          path={details.path}
          onChangeMethod={(method) => { this.updateDetailsValue("method", method) }}
          onChangePath={(path) => { this.updateDetailsValue("path", path) }}
        />
      </div>

      <RouteHandler
        key={`route-handler-0`}
        onChange={this.updateHandler}
        onDelete={this.deleteHandler}
        index={0}
        templates={this.state.templates}
        handler={details.handler.entries[0]}></RouteHandler>

      <div style={{
        position: 'absolute',
        transition: 'bottom 350ms',
        bottom: this.state.dirty ? '0px' : '-72px',
        width: '100%',
        left: 0,
        padding: '20px',
        boxSizing: 'border-box',
        background: '#ffffffd4'
      }}>
        {!!details.id ?
          <Button onClick={() => { this.saveDetails() }} type="primary" loading={this.state.saving} block>Save configuration</Button>
          :
          <Button onClick={() => { this.saveNewRoute() }} type="primary" loading={this.state.saving} block>Create new route</Button>
        }
      </div>
    </Drawer>
  }

  generateLogs() {
    const onClose = () => {
      this.setState({logsDetails: null})
    }

    if(!this.state.logsDetails || this.state.logsDetails === "") return;

    return <LogsPannel onClose={onClose} route={this.state.logsDetails} />
  }

  render() {
    return <React.Fragment>
      {this.generateList()}
      {this.generateDetails()}
      {this.generateLogs()}
    </React.Fragment> 
  }
}