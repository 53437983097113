import React from 'react';
import { Route, Redirect, Link } from "react-router-dom";
import { Layout, Menu, Icon, Select } from 'antd';

import User from '../../../services/user'
import Sites from '../../../services/sites'

const { Option } = Select;
const { Footer, Sider } = Layout;

const BASE_PATH = process.env.REACT_APP_PUBLIC_URL || "";


export default class DashboardRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  generateSidebar = () => {
    console.log("BASE_PATH: ", BASE_PATH);
    return (
      <Sider style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0}}>
        <div className="logo" style={{padding:"30px"}}>
          <img alt="logo" src="/logo.png" style={{width: '100%', borderRadius: 100}} />
        </div>
        {/* <p className="logged-as">{this.state.user.firstname} {this.state.user.lastname}</p> */}
        <Select
          defaultValue={Sites.getSelected().id}
          style={{ width: "100%", boxSizing: "border-box", padding: "0px 15px", marginBottom: "10px" }}
          onChange={(value) => {
            Sites.getOne(value).then((site) => {
              Sites.setSelected(site);
            })
          }}
        >
        {Sites.sites.map(site => (
          <Option key={site.id} value={site.id}>{site.name}</Option>
        ))}
        </Select>
        <Menu theme="dark" defaultSelectedKeys={[window.location.pathname]}>
          <Menu.Item key={`${BASE_PATH}/mock/routes`}>
            <Icon type="database" />
            <span className="nav-text">Routes</span>
            <Link to={`${BASE_PATH}/mock/routes`} />
          </Menu.Item>
          <Menu.Item key={`${BASE_PATH}/mock/categories`}>
            <Icon type="unordered-list" />
            <span className="nav-text">Categories</span>
            <Link to={`${BASE_PATH}/mock/categories`} />
          </Menu.Item>
          <Menu.Item key={`${BASE_PATH}/mock/templates`}>
            <Icon type="snippets" />
            <span className="nav-text">Templates</span>
            <Link to={`${BASE_PATH}/mock/templates`} />
          </Menu.Item>
          <Menu.Item key={`${BASE_PATH}/account`}>
            <Icon type="user" />
            <span className="user">Account</span>
            <Link to={`${BASE_PATH}/account`} />
          </Menu.Item>
          <Menu.Item className="navigation-logout-link" key={`${BASE_PATH}/logout`}>
            <Icon type="logout" />
            <span className="user">Logout</span>
            <Link to={`${BASE_PATH}/logout`} />
          </Menu.Item>
        </Menu>
      </Sider>
    )
  }

  render = () => {
    const { component: Component, ...rest } = this.props

    return <Route {...rest} render={(props) => {
      if(User.signedIn()) {
  
        return <Layout>
          {this.generateSidebar()}
          <Layout style={{ marginLeft: 200 }}>
          <Component {...rest} />
            <Footer style={{ textAlign: 'center' }}>Draw API ©2020 Created by Octopus Project</Footer>
          </Layout>
        </Layout>
      }
      return <Redirect to={`${process.env.REACT_APP_PUBLIC_URL || ""}/login`} />
    }} />
  }

}