class API {

  handlers = {
    unauthorized: []
  };

  setToken(token) {
    this.token = token;
  }

  onUnauthorized(callback) {
    if("function" == typeof callback) {
      this.handlers.unauthorized.push(callback)
    }
  }

  executeHandlers(action) {
    if(this.handlers[action] && this.handlers[action].length) {
      this.handlers[action].forEach(callback => {
        callback()
      })
    }
  }

  generateFetchOptions(method, body, headers) {
    let output = {method, headers: headers || {}, mode: 'cors'}

    if(body) {
      output.body = JSON.stringify(body)
    }
    if(this.token) {
      output.headers["Authorization"] = `Bearer ${this.token}`
    }

    return output;
  }

  request(path, options) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_TARGET}${path}`, options)
        .then(resp => {
          if (!resp.ok) {
            if(resp.status === 401) {
              this.executeHandlers("unauthorized")
            }
            return reject(resp)
          }
          return resp.json();
        })
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error);
        })
    })
  }

  get(path) {
    const options = this.generateFetchOptions("get")
    return this.request(path, options)
  }

  post(path, body) {
    const options = this.generateFetchOptions("post", body)
    return this.request(path, options)
  }

  put(path, body) {
    const options = this.generateFetchOptions("put", body)
    return this.request(path, options)
  }

  delete(path) {
    const options = this.generateFetchOptions("delete")
    return this.request(path, options)
  }
}

export default new API();