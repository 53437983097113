import React from 'react';
import { Select  } from 'antd';

const { Option } = Select;

export default class StatusCode extends React.Component {

  dataSource = [
    { value: 200, text: "200 - Ok" },
    { value: 404, text: "404 - Not Found" },
    { value: 500, text: "500 - Server Error" }
  ];

  updateValue = (value) => {
    this.props.onChange(value);
  }

  render() {
    const {value} = this.props;
    
    return <Select
      onChange={(value) => this.updateValue(value)}
      defaultValue="200"
      value={value}
      style={{ width: 260 }}>
      {this.dataSource.map(entry => {
        return <Option key={entry.value} value={entry.value}>{entry.text}</Option>
      })}
    </Select>
  }
}