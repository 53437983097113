import React from 'react';

import { Col, Input, Button  } from 'antd';


export default class Headers extends React.Component {

  constructor(props) {
    super(props);

    this.onChange = this.props.onChange || (() => {});
  }

  removeHeader = (index) => {
    this.props.headers.splice(index, 1);
    this.onChange(this.props.headers)
  }

  updateHeader = (index, header) => {
    this.props.headers[index] = header;
    this.onChange(this.props.headers)
  }

  addNewHeader = () => {
    let latestHeader = this.props.headers[this.props.headers.length-1];
    if(latestHeader && latestHeader[0] === "") {
      return
    }
    this.props.headers.push(["",""])
    this.onChange(this.props.headers)
  }

  render() {
    return <React.Fragment>
      {this.props.headers.map((header, index) => {
        return <Col span={24} key={`plain-config-headers-${index}`}>
          <Input
            onChange={(e) => {
              header[0] = e.target.value;
              this.updateHeader(index, header)
            }}
            style={{ width: 170, textAlign: 'left' }}
            value={header[0]}
            placeholder="Key" />
          <Input
            onChange={(e) => {
              header[1] = e.target.value;
              this.updateHeader(index, header)
            }}
            style={{ width: 170, textAlign: 'left' }}
            value={header[1]}
            placeholder="Value" />
          <Button type="link" icon="delete" size="small" onClick={() => {
            this.removeHeader(index)
          }} style={{color: "grey"}} />
        </Col>
      })}
      <Button type="dashed" onClick={() => {
        this.addNewHeader()
      }} block>Add header</Button>
    </React.Fragment>
  }
}