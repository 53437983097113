import Api from '../api';

class Routes {

  all(site, limit, offset) {
    return Api.get(`/${site}/routes`)
  }

  update(site, route) {
    return Api.put(`/${site}/routes?id=${route.id}`, route)
  }

  create(site, route) {
    return Api.post(`/${site}/routes`, route)
  }

  delete(site, routeId) {
    return Api.delete(`/${site}/routes/${routeId}`)
  }
}


export default new Routes()