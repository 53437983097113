import React from 'react';
import moment from 'moment';
import { List, Drawer, Col, Descriptions, Row, Input, Button } from 'antd';

import LogsService from '../../../../services/logs'

const InputGroup = Input.Group;

export default class LogsPannel extends React.Component {
    

  constructor(props) {
    super(props);

    this.state = {}

    this.load(this.props.route);
  }

  load(id) {
    LogsService
      .route(id)
      .then(logs => {
        this.setState({logs})
      })
  }

  reload = () => {
    this.load(this.props.route);
  }

  closeHandler() {
    return this.props.onClose || function() {};
  }

  setSelected = (log) => {
    this.setState({selected: log})
  }

  generateLogDetails() {

    if(!this.state.selected) return;

    const log = this.state.selected;

    return <Descriptions className="logs-details-card" bordered>
      <Descriptions.Item label="Date">{moment(log.time).format("MM/DD/YY, h:mm:ss")}</Descriptions.Item>
      <Descriptions.Item label="Method">{log.method}</Descriptions.Item>
      <Descriptions.Item label="Status Code">{log.status_code}</Descriptions.Item>
      <Descriptions.Item label="Latency">{log.latency}</Descriptions.Item>
      <Descriptions.Item label="Remote Address">{log.remote_address}</Descriptions.Item>
      <Descriptions.Item label="Path">{log.uri}</Descriptions.Item>
      <Descriptions.Item label="Headers">{Object.keys(log.header).map((key) => {
        return <p><b>{key}</b>: {log.header[key]}</p>
      })}</Descriptions.Item>
    </Descriptions>
  }

  getDisplayableLogs() {

    if(!this.state.searchQuery) return this.state.logs;

    return this.state.logs.filter(log => {
      return log.host.indexOf(this.state.searchQuery) > -1 || log.uri.indexOf(this.state.searchQuery) > -1 
    });
  }

  render() {

    if(!this.state.logs) return "";
    
    const logs = this.getDisplayableLogs()

    return <Drawer title="Logs" placement="bottom" height={window.innerHeight} onClose={this.closeHandler()} visible={!!this.state.logs} bodyStyle={{ background: "#f0f2f5" }} >
      <InputGroup size="small" style={{marginBottom: "20px"}}>
        <Row>
          <Col span={12}>
            <Input onChange={(e) => {
              this.setState({searchQuery: e.target.value});
            }} value={this.state.searchQuery} placeholder="Search query" />
          </Col>
          <Col span={11}>&nbsp;</Col>
          <Col span={1} style={{textAlign:"right"}}>
            <Button shape="circle" icon="reload" size="small" onClick={this.reload} />
          </Col>
        </Row>
      </InputGroup>
      <Row className="logs-list-head">
        <Col span={1}>Method</Col>
        <Col span={1}>Status</Col>
        <Col span={3}>Date time</Col>
        <Col span={1}>Latency</Col>
        <Col span={4}>Host</Col>
        <Col span={12}>Path</Col>
      </Row>
      <List
        className="demo-loadmore-list log-list"
        itemLayout="horizontal"
        split={false}
        dataSource={logs}
        renderItem={(item, index) => (
          <List.Item
            onClick={() => {
              if(this.state.selected === item) {
                this.setSelected(null);
              } else {
                this.setSelected(item)
              }
            }}
            size="small"
            className={`log-line ${this.state.selected === item ? "selected" : ""}`}
            key={`logs-line-${index}`}>
            <Col span={1}>{item.method}</Col>
            <Col span={1}>{item.status_code}</Col>
            <Col span={3}>{moment(item.time).format("MM/DD/YY, h:mm:ss")}</Col>
            <Col span={1}>{item.latency || "--"}</Col>
            <Col span={4}>{item.host}</Col>
            <Col span={12}>{item.uri}</Col>
          </List.Item>
        )}
      />
      {this.generateLogDetails()}
    </Drawer>
  }
}