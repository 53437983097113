import React from 'react';

import Sites from '../../../services/sites';

export default class Path extends React.Component {

  render() {
    const regex = /:[a-zA-Z]*/
    let htmlPath = {
      __html: (this.props.category || "")+this.props.path.replace(regex, `<span class="ant-typography ant-typography-warning">$&</span>`)
    }

    return <a href={`https://${Sites.getSelected().path}.drawapi.com${this.props.category || ""}${this.props.path}`} target="_blank" rel="noopener noreferrer">
      <span style={{fontSize: "0.9em", color: "#c0c0c0"}}>https://{Sites.getSelected().path}.drawapi.com</span>
      <span style={{color:"rgba(0, 0, 0, 0.65)"}} dangerouslySetInnerHTML={htmlPath}></span>
    </a>
  }
}