import Api from '../api'

class User {

    constructor() {
        this.token = localStorage.getItem("token");
        this.id = localStorage.getItem("user");
        this.sites = localStorage.getItem("sites");

        if(this.token) {
          Api.setToken(this.token)
        }
    }

    details() {
      return Api.get('/users/current')
    }

    update(account) {
      return Api.put('/users/current', account)
    }

    signin(credentials) {
      return new Promise((resolve, reject) => {
        const path = `/auth`
    
        Api
          .post(path, credentials)
          .then((resp) => {
            this.token = resp.token;
            this.id = resp.claims.user;
            this.sites = resp.claims.sites;

            Api.setToken(this.token)
            this.updateStore()
            resolve(resp)
          })
          .catch(reject)
      })
    }

    signedIn() {
      return !!this.token;
    }

    signout() {
      console.log("signout()")
      this.token = null;
      this.id = null;
      this.sites = null;
      
      console.log("this.token: ", this.token)
      console.log("this.id: ", this.id)
      console.log("this.sites: ", this.sites)
      this.updateStore()
    }

    updateStore() {
      this.id     ? localStorage.setItem('user', this.id)                     : localStorage.removeItem('user');
      this.token  ? localStorage.setItem('token', this.token)                 : localStorage.removeItem('token');
      this.sites  ? localStorage.setItem('sites', JSON.stringify(this.sites)) : localStorage.removeItem('sites');
    }

    dump() {
      console.log("+                -- USER --                +");
      console.log("ID:    ", this.id);
      console.log("Sites: ", this.sites);
      console.log("Token: ", this.token);
      console.log("+                -- ---- --                +");
    }
}



export default new User();