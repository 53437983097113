import React from 'react';
import Sites from '../../../../services/sites'
import Categories from '../../../../services/categories'
import Confirm from '../../../commons/confirm'

import { message, List, Skeleton, Col, Button, Icon, Drawer, Input } from 'antd';
export default class CategoriesList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      details: null,
      detailsIndex: null,
      dirty: false,
      categories: []
    }

    this.loadCategories();

    Sites.on('updateSelected', () => {
      this.loadCategories();
    })
  }

  loadCategories = () => {
    Categories
    .all(Sites.getSelected().id)
    .then(categories => {
      this.setState({categories})
    })
    .catch(error => {
      message.error('Unable to load Categories. Please, try again later');
      console.log(error);
    })
  }

  deleteCategory(id, index, callback) {
    Categories
      .delete(Sites.getSelected().id, id)
      .then(category => {
        let categories = this.state.categories;
        categories.splice(index, 1);
        this.setState({categories: categories});

        if("function" == typeof callback) {
          callback();
        }
      })
      .catch((error) => {
        message.error('Unable to delete Category');
        console.log(error)
      })
  }

  saveNewCategory = () => {
    this.setState({saving: true})

    Categories
      .create(Sites.getSelected().id, this.state.details)
      .then(category => {
        this.setState({saving: false, dirty: false})
        message.success("Category has been saved");
        this.state.categories.unshift(category)

        this.setState({categories: this.state.categories, details: null})
      })
      .catch(error => {
        message.success("Unable to save category");
        console.log("Error: ", error);
      })
  }

  saveDetails = () => {
    this.setState({saving: true})

    Categories
      .update(Sites.getSelected().id, this.state.details)
      .then(template => {
        let categories = this.state.categories;
        categories[this.state.detailsIndex] = JSON.parse(JSON.stringify(this.state.details));

        this.setState({saving: false, dirty: false, categories});
        message.success("Template has been saved");
      })
  }

  updateDetailsValue = (key, value) => {
    let state = this.state;
    state.details[key] = value;
    state.dirty = true;
    this.setState(state);
  }

  pushNewEmptyCategory = () => {
    console.log("pushNewEmptyTemplate")
    let emptyCategory = {
      site: Sites.getSelected().id,
      name: "",
      description: "",
      base_path: "/"
    };

    this.setState({details: emptyCategory})
  }

  onClose = () => {
    this.setState({details: null})
  }

  generateDetails = () => {
    if(!this.state.details) return;

    const {details} = this.state;

    return <Drawer
      title="Edit Categoriy"
      width={800}
      onClose={this.onClose}
      visible={!!this.state.details}
      bodyStyle={{ paddingBottom: 100, background: "#f0f2f5" }}
    >
      <div style={{ marginBottom: 16 }}>
        <label class="details">Category Name</label>
        <Input
          onChange={(e) => {
            this.updateDetailsValue("name", e.target.value)
          }}
          value={details.name}
        />
      </div>

      <div style={{ marginBottom: 16 }}>
      <label class="details">Category base path</label>
        <Input
          onChange={(e) => {
            this.updateDetailsValue("base_path", e.target.value)
          }}
          value={details.base_path}
        />
      </div>

      <div style={{ marginBottom: 16 }}>
        <label class="details">Category description</label>
        <Input
          onChange={(e) => {
            this.updateDetailsValue("description", e.target.value)
          }}
          value={details.description}
        />
      </div>

      <div style={{
        position: 'absolute',
        transition: 'bottom 350ms',
        bottom: this.state.dirty ? '0px' : '-72px',
        width: '100%',
        left: 0,
        padding: '20px',
        boxSizing: 'border-box',
        zIndex: '100',
        background: '#ffffffd4'
      }}>
        {!!details.id ?
          <Button onClick={() => { this.saveDetails() }} type="primary" loading={this.state.saving} block>Save configuration</Button>
          :
          <Button onClick={() => { this.saveNewCategory() }} type="primary" loading={this.state.saving} block>Create new category</Button>
        }
      </div>
    </Drawer>
  }

  render() {
    return <React.Fragment>
      {this.generateDetails()}
      <List
        className="demo-loadmore-list"
        // loading={initLoading} 
        itemLayout="horizontal"
        dataSource={this.state.categories}
        renderItem={(item, index) => (
          <List.Item
            key={`categories-list-line-${index}`}
            actions={[
              <Button key="list-loadmore-edit" type="link" onClick={() => { this.setState({dirty: false, detailsIndex: index, details: JSON.parse(JSON.stringify(item))}) }}><Icon type="edit" /></Button>,
              <Confirm key={`list-loadmore-delete-${index}`} onClick={(cb) => { return this.deleteCategory(item.id, index, cb) }}><Icon type="delete" /></Confirm>
            ]}
          >
            <Skeleton title={false} loading={item.loading} active>
              <List.Item.Meta
              title={
              <>
              <Col span={8}>{item.name}</Col>
              <Col span={6}>{item.base_path}</Col>
              <Col span={8}>{item.description}</Col>
              </>
              }
              />
              <div>
              </div>
            </Skeleton>
          </List.Item>
        )}
      />
      <div style={{width:"100%", padding:"5px 0", textAlign:"center"}}>
        <Button type="primary" icon="plus" size="small" onClick={this.pushNewEmptyCategory}>Create new Category</Button>
      </div>
    </React.Fragment>
  }
}