import React from 'react';

import RoutesPages from './pages/mock/routes';
import CategoriesPages from './pages/mock/categories';
import TemplatesPage from './pages/mock/templates';
import ApplicationError from './pages/error';

import AccountDetailPage from './pages/account/details';
import LoginPage from './pages/account/login';
import LogoutPage from './pages/account/logout';

import PrivateRoute from "./components/commons/privateRoute";

import User from './services/user'
import Sites from './services/sites'

import 'antd/dist/antd.css';
import './App.css';
import { Layout, Menu, Icon, Select } from 'antd';
import { BrowserRouter as Router, Redirect, Switch, Route, Link } from "react-router-dom";

const { Option } = Select;
const { Footer, Sider } = Layout;
const BASE_PATH = process.env.REACT_APP_PUBLIC_URL || "";

export default class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      redirecting: false,
      loading: true,
      user: {},
      serverStatus: "up"
    }

    User
      .details()
      .then(user => {
        this.setState({user})
      })

    Sites
      .getDefaultSite().then(() => {
        this.setState({loading: false})
      })
      .catch(resp => {
        if(!resp || resp.status !== 401) {
          this.setState({loading: false, serverStatus: "down"})
        }
        this.setState({loading: false})
      })
  }


  generateSignedInSideBar = () => {
    return (
      <React.Fragment>
        <p className="logged-as">{this.state.user.firstname} {this.state.user.lastname}</p>
        <Select
          defaultValue={Sites.getSelected().id}
          style={{ width: "100%", boxSizing: "border-box", padding: "0px 15px", marginBottom: "10px" }}
          onChange={(value) => {
            Sites.getOne(value).then((site) => {
              Sites.setSelected(site);
            })
          }}
        >
        {Sites.sites.map(site => (
          <Option key={site.id} value={site.id}>{site.name}</Option>
        ))}
        </Select>
        <Menu theme="dark" defaultSelectedKeys={[window.location.pathname]}>
          <Menu.Item key={`${BASE_PATH}/mock/routes`}>
            <Icon type="database" />
            <span className="nav-text">Routes</span>
            <Link to={`${BASE_PATH}/mock/routes`} />
          </Menu.Item>
          <Menu.Item key={`${BASE_PATH}/mock/categories`}>
            <Icon type="unordered-list" />
            <span className="nav-text">Categories</span>
            <Link to={`${BASE_PATH}/mock/categories`} />
          </Menu.Item>
          <Menu.Item key={`${BASE_PATH}/mock/templates`}>
            <Icon type="snippets" />
            <span className="nav-text">Templates</span>
            <Link to={`${BASE_PATH}/mock/templates`} />
          </Menu.Item>
          <Menu.Item key={`${BASE_PATH}/account`}>
            <Icon type="user" />
            <span className="user">Account</span>
            <Link to={`${BASE_PATH}/account`} />
          </Menu.Item>
          <Menu.Item className="navigation-logout-link" key={`${BASE_PATH}/logout`}>
            <Icon type="logout" />
            <span className="user">Logout</span>
            <Link to={`${BASE_PATH}/logout`} />
          </Menu.Item>
        </Menu>
      </React.Fragment>
    )
  }
  
  generateNotSignedInSideBar = () => {
    return (<Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
      <Menu.Item key="1">
        <Icon type="user" />
        <span className="user">Login</span>
        <Link to={`${BASE_PATH}/login`} />
      </Menu.Item>
    </Menu>)
  }

  generateSidebar = () => {
    return (
      <Sider a={this.state.foo} style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0}}>
        <div className="logo" style={{padding:"30px"}}>
          <img alt="logo" src="/logo.png" style={{width: '100%', borderRadius: 100}} />
        </div>
        {User.signedIn() ? this.generateSignedInSideBar() : this.generateNotSignedInSideBar()}
      </Sider>
    )
  }

  loadingStatus = () => {
    return "Loading ..."
  }

  downStatus = () => {
    return <ApplicationError message="An error has occurred, please try again later." />
  }

  dashboardLayout = (Component) => {
    // Déporter le layout dans le composant de la route (PrivateRoute ==> DashboardRoute)
    return () => {
      return <Layout>
        {this.generateSidebar()}
        <Layout style={{ marginLeft: 200 }}>
          <Component />
          <Footer style={{ textAlign: 'center' }}>Draw API ©2020 Created by Octopus Project</Footer>
        </Layout>
      </Layout>
    }
  }

  generateLayout = () => {
    if(this.state.loading) {
      return this.loadingStatus();
    }
    // if(this.state.serverStatus === "down") {
    //   return this.downStatus();
    // }
    return <Switch>

      {/* Mock Section */}
      <PrivateRoute exact path={`${BASE_PATH}/mock/routes`} component={RoutesPages} />
      <PrivateRoute path={`${BASE_PATH}/mock/routes/:id`} component={RoutesPages} />
      <PrivateRoute path={`${BASE_PATH}/mock/categories`} component={CategoriesPages} />
      <PrivateRoute path={`${BASE_PATH}/mock/templates`} component={TemplatesPage} />

      {/* Account Section */}
      <Route path={`${BASE_PATH}/login`} component={LoginPage}></Route>
      <Route path={`${BASE_PATH}/logout`} component={LogoutPage} />

      <PrivateRoute path={`${BASE_PATH}/account`} component={AccountDetailPage} />

      <Redirect exact from={`*`} to={`${BASE_PATH}/mock/routes`} />
    </Switch>
  }

  render() {

    return (
      <Router>
        {this.generateLayout()}
      </Router>
    );
  }
}
