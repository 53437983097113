import Api from '../api';

class Templates {
  templates = [];

  getOne(id) {
    return new Promise((resolve, reject) => {
      if(this.templates) {
        let localTemplate = this.templates.filter(template => template.id === id).pop();
        if(localTemplate) {
          return resolve(localTemplate);
        }
      }
    });
  }

  all(site, limit, offset) {
    return Api.get(`/${site}/templates`)
  }

  create(site, template) {
    return Api.post(`/${site}/templates`, template)
  }

  update(site, template) {
    return Api.put(`/${site}/templates/${template.id}`, template)
  }

  delete(site, templateId) {
    return Api.delete(`/${site}/templates/${templateId}`)
  }
}


export default new Templates()