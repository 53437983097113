import Api from '../api';

class Categories {

  path = "categories";

  all(site, limit, offset) {
    return Api.get(`/${site}/${this.path}`)
  }

  update(site, category) {
    return Api.put(`/${site}/${this.path}/${category.id}`, category)
  }

  create(site, category) {
    return Api.post(`/${site}/${this.path}`, category)
  }

  delete(site, categoryId) {
    return Api.delete(`/${site}/${this.path}/${categoryId}`)
  }
}

export default new Categories()