import React from 'react';
import { Redirect } from 'react-router-dom'
import User from '../../../services/user'

class LogoutPage extends React.Component {
  
  render() {
    console.log("LogoutPage");
    User.signout()

    return <Redirect to={`${process.env.REACT_APP_PUBLIC_URL || ""}/login`} />
  }
}

export default LogoutPage