import React from 'react';
import { withRouter } from 'react-router-dom'
import User from '../../../services/user'
import Sites from '../../../services/sites'
import { Layout, Form, Input, Button, message } from 'antd';

const { Content } = Layout;

class LoginPage extends React.Component {

  state = {
    login: {}
  };

  handleSubmit = (e) => {
    e.preventDefault();
    
    User
      .signin(this.state.login)
      .then((resp) => {
        message.success("User has been logged in");
        Sites
          .getDefaultSite().then(() => {
            this.props.history.push(`${process.env.REACT_APP_PUBLIC_URL || ""}/mock/routes`)
          })
      })
      .catch(e => {
        console.log('E: ', e);
        message.error("User can't be logged in");
      })
  };

  updateLoginValue = (key, value) => {
    let login = this.state.login;

    login[key] = value;

    this.setState({login})
  }
  
  render() {

    return <>
      <Content>
        <Form className="login-form" onSubmit={this.handleSubmit}>
          <div className="logo" style={{padding:"0px 50px 15px 50px"}}>
            <img alt="logo" src="/logo.png" style={{width: '100%', borderRadius: 400}} />
          </div>
          <h1>Welcome to Draw API</h1>
          <Form.Item label="E-mail">
            <Input placeholder="Your E-mail" onChange={(e) => { this.updateLoginValue("login", e.target.value) }} />
          </Form.Item>

          <Form.Item label="Password" onChange={(e) => { this.updateLoginValue("password", e.target.value) }} hasFeedback>
            <Input.Password autoComplete="new-password"   />
          </Form.Item>

          <Button style={{width: "100%"}} type="primary" htmlType="submit" className="login-form-button">
            Sign In
          </Button>
        </Form>
      </Content>
    </>
  }
}

export default withRouter(LoginPage)