import React from 'react';
import { Input, Select  } from 'antd';

const InputGroup = Input.Group;
const { Option } = Select;


export default class Latency extends React.Component {
  
  units = [
    {name: "ms", value: "ms"},
    {name: "sec", value: "s"},
  ];
  defaultValue = "ms"

  updateLantency = (key, value) => {
    if(key === "from" || key === "to") {
      value = parseInt(value);
    }
    this.props.latency[key] = value;
    this.props.onChange(this.props.latency)
  } 
  
  render() {
    return <InputGroup compact>
      <Input
        onChange={(e) => { this.updateLantency("from", e.target.value) }}
        style={{ width: 70, textAlign: 'center' }}
        value={this.props.latency.from}
        placeholder="Minimum" />
      <Input style={{ width: 30, borderLeft: 0, pointerEvents: 'none', backgroundColor: '#fff' }} placeholder="~" disabled />
      <Input
        onChange={(e) => { this.updateLantency("to", e.target.value) }}
        style={{ width: 70, textAlign: 'center', borderLeft: 0 }}
        value={this.props.latency.to}
        placeholder="Maximum" />
      <Select 
        onChange={(e) => { this.updateLantency("unit", e) }}
        defaultValue={this.defaultValue}
        value={this.props.latency.unit}>
        {this.units.map(unit => {
          return <Option key={unit.value} value={unit.value}>{unit.name}</Option>

        })}
      </Select>
    </InputGroup>
  }
}