import React from 'react';
import { Button } from 'antd';

const ButtonGroup = Button.Group;

export default class Component extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isClicked: false
        };
    }

    markAsClicked = () => {
        this.setState({isClicked: true});
    }

    resetState = () => {
        this.setState({isLoading: false, isClicked: false})
    }

    handler = () => {
        this.setState({isLoading: true});
        this.props
            .onClick(() => {
                this.resetState()
            })
    }

    cancel = () => {
        this.resetState()
    }

    render() {
        if(this.state.isClicked) {
            return <ButtonGroup>
                <Button type="danger" onClick={this.handler} loading={this.state.isLoading}>Confirm ?</Button>
                <Button type="dashed" onClick={this.cancel}>Cancel</Button>
            </ButtonGroup>
        }
        return <Button onClick={this.markAsClicked} type="link">{this.props.children}</Button>
    }
}