import React from 'react';
import RoutesList from '../../../components/mock/routes/list'
import { PageHeader, Descriptions, Layout } from 'antd';

const { Content } = Layout;


export default class RoutesPages extends React.Component {
  render() {
    return <>
      <PageHeader ghost={false} title="Mocks → Routes">
        <Descriptions size="small" column={3}>
          <Descriptions.Item>Mocks management</Descriptions.Item>
        </Descriptions>
      </PageHeader>

      <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        <div style={{ padding: 24, background: '#fff', textAlign: 'left' }}>
          <RoutesList {...this.props}></RoutesList>
        </div>
      </Content>
    </>
  }
}