import React from 'react';

import { Tag } from 'antd';


export default class MethodTag extends React.Component {

  getTagColorFromMethod = (name) => {
    switch(name) {
      case 'GET':
        return "green";
      case 'POST':
        return "blue";
      case 'PUT':
        return "orange";
      case 'DELETE':
          return "red";
      default:
        return ""
    }
  }

  render() {
    return <Tag color={this.getTagColorFromMethod(this.props.method)}>{this.props.method}</Tag>
  }
}