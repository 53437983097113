import React from 'react';
import CategoriesList from '../../../components/mock/categories/list'
import { Layout, PageHeader } from 'antd';

const { Content } = Layout;


export default class CategoriesPages extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      initLoading: true,
      loading: false,
      data: [],
      list: [],
    };
  }

  render() {
    return <>
      <PageHeader ghost={false} title="Mocks → Categories"></PageHeader>
      <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        <div style={{ padding: 24, background: '#fff', textAlign: 'left' }}>
          <CategoriesList></CategoriesList>
        </div>
      </Content>
    </>
  }
}