import React from 'react';
import { List, Drawer, Skeleton, Select, Input, Button, Icon, Col, message } from 'antd';
import CodeMirror from 'react-codemirror';
import Confirm from '../../../commons/confirm'

import Sites from '../../../../services/sites'
import Templates from '../../../../services/templates'

import 'codemirror/lib/codemirror.css';
import '../../../../../node_modules/codemirror/mode/xml/xml'
import '../../../../../node_modules/codemirror/mode/javascript/javascript'

const { Option } = Select;

export default class TemplatesList extends React.Component {

  state = {
    initLoading: false,
    loading: false,
    saving: false,
    details: null,
    detailsIndex: null,
    dirty: false,
    data: [],
    list: [],
    templates: []
  }

  constructor(props) {
    super(props)

    this.loadTemplates()

    Sites.on('updateSelected', () => {
      this.loadTemplates();
    })
  }

  loadTemplates() {
    Templates
      .all(Sites.getSelected().id)
      .then(templates => {
        this.setState({templates})
      })
  }

  deleteTemplates(id, index, callback) {
    Templates
      .delete(Sites.getSelected().id, id)
      .then(template => {
        let templates = this.state.templates;
        templates.splice(index, 1);
        this.setState({templates: templates});

        if("function" == typeof callback) {
          callback();
        }
      })
      .catch((error) => {
        message.error('Unable to delete Template');
        console.log(error)
      })
  }

  saveDetails = () => {
    this.setState({saving: true})

    Templates
      .update(Sites.getSelected().id, this.state.details)
      .then(template => {
        let templates = this.state.templates;
        templates[this.state.detailsIndex] = JSON.parse(JSON.stringify(this.state.details));

        this.setState({saving: false, dirty: false, templates});
        message.success("Template has been saved");
      })
  }

  saveNewTemplate = () => {
    this.setState({saving: true})

    Templates
      .create(Sites.getSelected().id, this.state.details)
      .then(template => {
        this.setState({saving: false, dirty: false})
        message.success("Template has been saved");
        this.state.templates.unshift(template)

        this.setState({templates: this.state.templates, details: null})
      })
      .catch(error => {
        message.success("Unable to save template");
        console.log("Error: ", error);
      })
  }

  updateDetailsValue = (key, value) => {
    let state = this.state;
    state.details[key] = value;
    state.dirty = true;
    this.setState(state);
  }

  updateMeta = (key, value) => {
    let details = this.state.details;
    details.meta[key] = value;
    this.setState({details})
  }

  onClose = () => {
    this.setState({details: null})
  }

  pushNewEmptyTemplate = () => {
    let emptyTemplate = {
      title: "",
      site: Sites.getSelected().id,
      description: "",
      content: "",
      meta: {
        "Content-type": "plain/text"
      }
    };

    this.setState({details: emptyTemplate})
  }

  generateList = () => {
    const { initLoading, templates } = this.state;

    return <>
      <List
      className="demo-loadmore-list"
      loading={initLoading} 
      itemLayout="horizontal"
      renderItem={(item, index) => (
        <List.Item
          key={`routes-list-line-${index}`}
          actions={[
            <Button key="list-loadmore-edit" type="link" onClick={() => { this.setState({dirty: false, detailsIndex: index, details: JSON.parse(JSON.stringify(item))}) }}><Icon type="edit" /></Button>,
            <Confirm key="list-loadmore-delete" onClick={(cb) => { return this.deleteTemplates(item.id, index, cb) }}><Icon type="delete" /></Confirm>
          ]}>
          <Skeleton title={false} loading={item.loading} active>
            <List.Item.Meta
              title={
                <>
                  <Col span={6}>{item.name || "Unnamed Template"}</Col>
                  <Col span={12}>{item.description}</Col>
                  <Col span={4}>{item.meta["Content-type"]}</Col>
                </>
              }
            />
          </Skeleton>
        </List.Item>
      )}
      dataSource={templates} />
      <div style={{width:"100%", padding:"5px 0", textAlign:"center"}}>
        <Button type="primary" icon="plus" size="small" onClick={this.pushNewEmptyTemplate}>Create new Template</Button>
      </div>
    </>
  }

  generateDetails = () => {
    if(!this.state.details) return;

    const {details} = this.state;

    const options = ["text/plain", "application/xml", "application/json"]

    return <Drawer
      title="Edit Template"
      width={1050}
      onClose={this.onClose}
      visible={!!this.state.details}
      bodyStyle={{ paddingBottom: 100, background: "#f0f2f5" }}
    >
      {/* Suppression / Distribution / Variables */}
      <div style={{ marginBottom: 16 }}>
        <label class="details">Template Name</label>
        <Input
          onChange={(e) => {
            this.updateDetailsValue("name", e.target.value)
          }}
          value={details.name}
        />
      </div>

      <div style={{ marginBottom: 16 }}>
        <label class="details">Content Type</label>
        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Select a Content Type"
          optionFilterProp="content type"
          value={details.meta["Content-type"]}
          onChange={(value) => {
            this.updateMeta("Content-type", value);
          }}
          filterOption={(input, option) =>
            options.indexOf(input.toLowerCase()) >= 0
          }
        >
          {options.map((entry, index) => (
            <Option key={entry} value={entry}>{entry}</Option>
          ))}
        </Select>
      </div>

      <label class="details">Content</label>
      <CodeMirror
        value={details.content}
        options={{
          mode: 'xml',
          lineNumbers: true
        }}
        onChange={(value) => {
          this.updateDetailsValue("content", value)
        }}
      />

      <div style={{
        position: 'absolute',
        transition: 'bottom 350ms',
        bottom: this.state.dirty ? '0px' : '-72px',
        width: '100%',
        left: 0,
        padding: '20px',
        boxSizing: 'border-box',
        zIndex: '100',
        background: '#ffffffd4'
      }}>
        {!!details.id ?
          <Button onClick={() => { this.saveDetails() }} type="primary" loading={this.state.saving} block>Save configuration</Button>
          :
          <Button onClick={() => { this.saveNewTemplate() }} type="primary" loading={this.state.saving} block>Create new template</Button>
        }
      </div>
    </Drawer>
  }

  render() {
    return <React.Fragment>
      {this.generateList()}
      {this.generateDetails()}
    </React.Fragment> 
  }
}